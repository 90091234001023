import Menu from './components/Menu';
import logo from './logo.svg';

export default function Header() {
  return (
    <div className='md:flex justify-between items-center md:px-24 md:pt-14 md:pb-7'>
      <img src={logo} className='hidden md:block m-4 md:m-0 w-24' alt='logo' />
      <Menu initial="HUMANE AI" second="HUMANE AI について" />
    </div>
  );
}
