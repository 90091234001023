import { humanai } from '../images';

export default function Slogan() {
  return (
    <>
      <div className='relative px:10 md:px-20 md:py-56 z-20'>
        <div className='w-full md:w-1/2 font-bold text-lg md:text-5xl text-[#262626] text-center md:text-left'>
          HUMANE AIを使えば、
          <br />
          交流はもっと楽しくなる！
        </div>
      </div>
      <img src={humanai} alt='' className='absolute top-4 md:top-0 right-0 -z-10' />
    </>
  );
}
