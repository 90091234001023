import Title from './Title';

export default function AboutRuppi() {
  return (
    <div className='p-8 md:py-24 md:px-32 md:mx-24 md:my-36 bg-[#FFF1EA] opacity-75'>
      <Title text='RUPPIとは' />
      <div className='md:my-14 text-left inline-block text-lg md:text-2xl font-bold'>
        <div>
          人と人が簡単に繋がる技術を開発するHumane AIの会社です。
        </div>
        <div>
          革新的な技術を融合したヒト検索AI（大規模人間モデル）をつかって、自分の希望する人々、
        </div>
        <div>
          自分と気が合う仲間と、確実に巡り合うことができる世界を実現します。
        </div>
        <div>
          すべての人が自分の持つ無数の個性を分かち合う「ヒト」を見つけられますように。
        </div>
      </div>
    </div>
  );
}
