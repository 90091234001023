import { forwardRef } from 'react';
import { facebook, insta, tiktok, twitter } from '../svgs';

const Contact = forwardRef((_, ref) => {
  return (
    <div className='bg-black p-4 md:px-48 md:py-28 text-lg md:text-2xl text-[#FBFAFA]' ref={ref}>
      <div className='my-8 text-left'>
        <p>(米)Ruppi Inc</p>
        <p>(日本子会社)ソーシャルグッド株式会社</p>
        <p>創立年：2023年6月</p>
      </div>
      <div className='my-8 text-left'>
        <p>問い合わせ先:</p>
        <p>design@ruppi.jp</p>
      </div>
      <div className='my-8 text-left'>
        <p>本社所在地:</p>
        <p>2321 Sheridan Road, Evanston, IL, 60201</p>
      </div>
      <div className='my-8 text-left'>
        <p>日本子会社所在地：</p>
        <p>東京都世田谷区北沢2丁目2-7NFビル５F</p>
      </div>
      <div className='flex-row flex'>
        <a href='https://www.instagram.com/ruppi_official'>
          <img src={insta} className='w-8 md:w-14 mr-12' alt='insta' />
        </a>
      </div>
    </div>
  );
});

export default Contact;
