import { doubleScreen, singleScreen, tripleScreen } from '../images';
import Title from './Title';

export default function Introduction() {
  return (
    <div className='mt-4 md:mt-0 md:mx-32'>
      <Title text='HUMANE AI 機能紹介' />
      <Section
        text='自己紹介文はHUMANE AIにお任せ。人検索はHUMANE AI モードを選び、要望を伝えるだけで簡単に準備が整います。'
        image={tripleScreen}
      />
      <Section
        text='お互いの要望に合っていればでプロフィールが見えるようになり、チャットや音声通話ができます。HUMANE AIはあなたと他人のつながりを支えています。'
        image={doubleScreen}
        reversed
      />
      <Section
        text='継続的に交流したい場合はハッシュタグを付けて友達追加。複数の趣味や学業、仕事に合わせて友達をグループ分けできます。HUMANE AIにフィードバックを提供できます。'
        image={singleScreen}
      />
    </div>
  );
}

const Section = ({ text, image, reversed }) => {
  const className = reversed
    ? 'flex flex-col md:flex-row-reverse my-4 md:my-16 items-center '
    : 'flex flex-col md:flex-row my-4 md:my-16 items-center ';
  return (
    <div className={className}>
      <div className='md:w-1/2 flex justify-center'>
        <img src={image} alt='' className='w-1/2' />
      </div>
      <div className='md:w-1/2 flex justify-center'>
        <div className='px-4 md:px-0 text-lg w-full md:w-2/3 md:text-2xl font-medium text-black text-justify'>
          {text}
        </div>
      </div>
    </div>
  );
};
