import './App.css';
import Header from './Header';

import { AboutRuppi, Contact, HumanAi } from './components';
import Slogan from './components/Slogan';
import Introduction from './components/Introduction';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

function App() {
  const routes = useRoutes([
    {
      path: '/ruppi',
      element: (
        <>
          <AboutRuppi />
          <Introduction />
        </>
      ),
    },
    { path: '/humanai', element: <HumanAi /> },
    {
      path: '/',
      element: <Slogan />,
    },
  ]);

  return (
    <div className='App'>
      <Header text='RUPPI' />
      {routes}
      <Contact />
    </div>
  );
}

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};


export default AppWrapper;
