export default function Menu({ initial, second }) {
  return (
    <div
      className='block md:flex md:flex-row border-2 text-lg md:text-xl text-black font-bold md:font-normal border-[#262626] mt-4 mx-4 md:px-20 md:py-7 md:items-center z-40'
      style={{ borderRadius: '1rem' }}
    >
      <a href='/' target='_blank'>
        ホーム
      </a>
      <div className='w-28' />
      <a href='/ruppi' target='_blank'>
        {initial}
      </a>
      <div className='w-28' />
      <a href='/humanai' target='_blank'>
        {second}
      </a>
    </div>
  );
}
