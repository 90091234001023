import { genderGraph, leftEdge, rightEdge, socialGraph } from '../images';
import Title from './Title';

export default function HumanAi() {
  return (
    <div>
      <Main/>
      <Images />
    </div>
  );
}

const Main = () => (
  <div className='md:relative md:px-56 mt-4 md:mt-36'>
    <div className='hidden md:block absolute left-0'>
      <img src={leftEdge} alt='' />
    </div>
    <div className='md:my-16'>
      <Title text='HUMANE AIとは' />
    </div>
    <div className='p-8 md:p-0 text-start'>
      <div className='font-medium text-sm md:text-2xl'>
        HUMANE
        AIは、既存の生成系AIが理解できる言語に限らず、ユーザーー人一人の心踊るテーマやアクティビティについて学習し、個人の嗜好や最新の関心ごとに合った交流対象を適宜提案します。
        <br />
        <br />
        2023年、日本人の3人に1人、20代女性の2人に1人が孤独を感じています。※1また、米国保健福祉省（HIHS）医務総監による報告では「LONELINESS(孤独)の流行が顕著であり、孤独は1日に15本のタバコを吸うのと同じくらい致命的である」と警告しています※2(表1参照）この背景には、新しい環境や場所に移ることが当たり前になると同時に、あらゆる情報が飛び交っている現代社会になり、お互いを理解し合える会話やコミュニティの確保が難しくなったことが関連しています。
        <br />
        <br />
        HUMANE
        AIを使えば、ライフステージの変化に伴って変化する個人の関心をAIがキャッチし、それを共有できる「ヒト」探しを高い精度と広い選択肢の中から選ぶことができ、全方位的な対人関係を構築できます。そして、HUMANE
        AIが社会に実装されれば(特に女性において孤独率が大きく低下することが期待されています。※3
        (表2参照)
        <br />
        <br />
      </div>
      <div className='font-medium text-xs md:text-sm'>
        ※1 内閣官房「人々のつながりに関する基礎調査」(令和3年)
        調査対象:全国の16歳以上の個人 20,000 人
      </div>
      <div className='font-medium text-xs md:text-sm'>
        ※2 Our Epidemic of Loneliness and Isolation The U.S.
        米国保健福祉省（HHS） 2023
      </div>
      <div className='font-medium text-xs md:text-sm'>
        ※3 Perceptions of social rigidity predict loneliness across the Japanese
        population Ryan P. Badman, Robert Nordström, Michiko Ueda & Rei Akaishi
      </div>
    </div>
  </div>
);

const Images = () => (
  <div className='block md:flex md:flex-row justify-between md:mt-36 mb-8 px-8 md:mb-72 md:px-56 md:relative'>
    <div className='hidden md:block absolute right-0 bottom-0'>
      <img src={rightEdge} alt='' />
    </div>
    <div className='text-center w-full mb-8 md:mb-0 md:w-1/2 md:mr-8'>
      <img src={socialGraph} alt='' className='object-scale-down' />
    </div>
    <img src={genderGraph} alt='' className='md:w-1/2 object-scale-down' />
  </div>
);